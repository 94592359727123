.home h1 {
	color: $gray;
	font-weight: 700;
	font-size: 32px;
	text-align: center;
}
.home h1.heroh1 {
	@include font-size(4.3rem);
	color: #fff;
	font-weight: 700;
	text-align: left;
	line-height: 1.1;
	margin: 0 0 2rem 0;
}
.home h2 {
	color: $gray;
	font-weight: 400;
	font-size: 4rem;
	text-align: center;
}

.home h4 {
	color: #fff;
	font-weight: 700;
	font-size: 38px;
	margin: 10px 0;
}

.home h5 {
	color: #fff;
	font-weight: 700;
	font-size: 24px;
	font-style: italic;
	margin: 0;
}

.home .our-process-band h5 {
	color: #fff;
	font-weight: 700;
	font-size: 54px;
	margin: 0;
	line-height: 100%;
	font-style: normal;
}

.home-hero p {
	color: #FFF;
	font-size: 24px;
}

.home-customers {
	padding: 4rem 0;
}

.home-accountability {

	text-align: center;
	padding: 4rem .5rem;
	.speed-icon {
		height: 50px;
	}
	.service-icon {
		height: 50px;
	}
	.solution-icon {
		height: 50px;
	}
	h3{
		font-size: 2.7rem;
		font-weight: 700;
		text-transform: uppercase;
	}
	p {
		color: #FFF;
		margin: 0;
		font-size: 22px;
		height: 80px;
	}
	.stat{
		color: #173c4d;
		font-weight: 900;
		font-size: 5rem;
	}

}

.home-testimonials {
	padding: 100px 0;
	border-bottom: 1px solid $gray;
	h2{
		margin: 0 0 60px 0;
	}
}

.home-industries {
	padding: 100px 0;
	h3{
		color: #173c4d;
		font-size: 24px;
	}
}

.home-sustainability {
	background-color: #e4fcf0;
	padding: 80px 0;
	h2 {
		margin: 0 0 50px 0;
	}
	h3{
		color: #173c4d;
		font-size: 24px;
		text-align: left;
	}
	p {
		font-size: 24px;
	}
	p.small{font-size: 18px;}
}
.home-solutions {
	padding: 80px 0;
}

.packaging-solutions .package {
	text-align: center;
	color: #535050;
}
.packaging-solutions span {
	display: block;
	font-size: 20px;
	margin: 10px 0;
	line-height: 100%;
}

.cycle-wrap {
	position: relative;
}

.cycle-pager {
	/*position: absolute;*/
	z-index: 7777;
	width: 280px;
	height: 280px;
	background: url(../img/pager.jpg) no-repeat;
	margin: 45px 0;
}
.cycle-pager li {
	list-style: none;
	padding: 0;
	margin: 0;
}
.cycle-pager a.transport,
.cycle-pager a.retail,
.cycle-pager a.medical,
.cycle-pager a.food {
	display: block;
	width: 280px;
	height: 70px;
}
.cycle-pager a.transport:hover {
	background: url(../img/pager.jpg) -280px 0;
}
.cycle-pager .cycle-pager-active .transport {
	background: url(../img/pager.jpg) -280px 0;
}
.cycle-pager a.retail:hover {
	background: url(../img/pager.jpg) -280px -70px;
}
.cycle-pager .cycle-pager-active .retail {
	background: url(../img/pager.jpg) -280px -70px;
}
.cycle-pager a.medical:hover {
	background: url(../img/pager.jpg) -280px -140px;
}
.cycle-pager .cycle-pager-active .medical {
	background: url(../img/pager.jpg) -280px -140px;
}
.cycle-pager a.food:hover {
	background: url(../img/pager.jpg) -280px -210px;
}
.cycle-pager .cycle-pager-active .food {
	background: url(../img/pager.jpg) -280px -210px;
}

.cycle-slideshow-api {
	height: 410px;
}
.cycle-slideshow-api > div {
	height: auto;
	width: 100%;
}
.cycle-slideshow-api div .hero-container {
	width: 1170px;
	margin: 0 auto 0 auto;
	padding: 70px 0 0 320px;
	position: relative;
}
.cycle-slideshow-api p {
	font-size: 20px;
	font-weight: 400;
	color: #fff;
}
.cycle-slideshow-api div.hero-brand {
	background: #5a120f;
	background: -moz-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #5a120f),
		color-stop(100%, #ed1b2e)
	);
	background: -webkit-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -o-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -ms-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: linear-gradient(to bottom, #5a120f 0%, #ed1b2e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a120f', endColorstr='#ed1b2e',GradientType=0 );
}
.cycle-slideshow-api div.hero-brand span {
	display: block;
	color: #fff;
	font-weight: 700;
	font-size: 60px;
	line-height: 52px;
	margin: 20px 0 0 0;
}
.cycle-slideshow-api div.hero-brand .spansub1 {
	display: block;
	color: #fff;
	font-weight: 400;
	font-size: 42px;
	line-height: 52px;
	font-style: none;
	margin: 10px 0 0 0;
}
.cycle-slideshow-api div.hero-brand .spansub2 {
	display: block;
	color: #fff;
	font-weight: 700;
	font-size: 32px;
	line-height: 52px;
	font-style: none;
	margin: 0 0 25px 0;
}
.cycle-slideshow-api div.hero-hero {
	/*+redGradient($darkred, $red)*/
	background: url(../img/hero-hero.jpg) center no-repeat;
}
.cycle-slideshow-api div.hero-transport {
	background: #100425;
	background: -moz-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #100425),
		color-stop(100%, #674c94)
	);
	background: -webkit-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -o-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -ms-linear-gradient(top, #100425 0%, #674c94 100%);
	background: linear-gradient(to bottom, #100425 0%, #674c94 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#100425', endColorstr='#674c94',GradientType=0 );
}
.cycle-slideshow-api div.hero-transport span {
	display: block;
	color: #e7dafd;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 42px;
	line-height: 52px;
}
.cycle-slideshow-api div.hero-transport img {
	position: absolute;
	top: 20px;
	right: -70px;
	z-index: -1;
}
.cycle-slideshow-api div.hero-retail {
	background: #062a0f;
	background: -moz-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #062a0f),
		color-stop(100%, #087e2a)
	);
	background: -webkit-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -o-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -ms-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: linear-gradient(to bottom, #062a0f 0%, #087e2a 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#062a0f', endColorstr='#087e2a',GradientType=0 );
}
.cycle-slideshow-api div.hero-retail span {
	display: block;
	color: #d2fcde;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 42px;
	line-height: 52px;
}
.cycle-slideshow-api div.hero-retail img {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}
.cycle-slideshow-api div.hero-medical,
.cycle-slideshow-api div.hero-brand-blue {
	background: #173c4c;
	background: -moz-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #173c4c),
		color-stop(100%, #5194af)
	);
	background: -webkit-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -o-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -ms-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: linear-gradient(to bottom, #173c4c 0%, #5194af 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#173c4c', endColorstr='#5194af',GradientType=0 );
}

div.hero-brand-black {
	background: linear-gradient(to top, #000 0%, #898989 100%);
	width: 100%;
}
.home-hero {
	padding: 5rem 1rem;
}

.cycle-slideshow-api div.hero-medical span {
	display: block;
	color: #8dc8e4;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 42px;
	line-height: 52px;
}
.cycle-slideshow-api div.hero-medical img {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}
.cycle-slideshow-api div.hero-food {
	background: #913909;
	background: -moz-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #913909),
		color-stop(100%, #fead6c)
	);
	background: -webkit-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -o-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -ms-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: linear-gradient(to bottom, #913909 0%, #fead6c 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#913909', endColorstr='#fead6c',GradientType=0 );
}
.cycle-slideshow-api div.hero-food span {
	display: block;
	color: #fccfac;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 42px;
	line-height: 52px;
}
.cycle-slideshow-api div.hero-food img {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}

.mobile-hero {
	width: 100%;
	background: linear-gradient(to top, #000 0%, #898989 100%);
	padding: 20px 0;
}
.mobile-hero .style-select {
	width: 100%;
	height: 38px;
	overflow: hidden;
	background: url(../img/arrow-down.jpg) no-repeat right #fff;
	border: 1px solid #fff;
	margin: 20px auto 25px auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
}
.mobile-hero .style-select select {
	background: transparent;
	width: 100%;
	padding: 0 10px;
	font-weight: 400;
	font-size: 1.3em;
	line-height: 1;
	border: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	height: 38px;
	-webkit-appearance: none;
	color: #535050;
}


.design-dev {
	border-top: 1px solid #e0e0e0;
	padding: 75px 0 !important;
}

.design-dev img {
	border: 2px #e0e0e0 solid;
}
.design-dev p {
	color: $gray;
}

.blue-band-home {
	width: 100%;
	background: #173c4d;
	background: linear-gradient(to bottom, #173c4d 0%, #5092ad 100%);
	padding: 4rem 0;
	h2 {
		color: #FFF;
	}
}

.resources .resource-bucket {
	margin: 50px 0;
	padding: 25px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	min-height: 420px;
}

.resources h3 {
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	text-align: center;
	margin: 2rem 0 3rem 0;
	text-transform: uppercase;
	letter-spacing: 0.7rem;
}

.resources .headline,
.resources .headline > a {
	font-weight: 400;
	font-size: 22px;
	color: #8dc8e4;
}

.resources .headline > a:hover {
	text-decoration: none;
	color: #ffffff;
}

.resources .post-date {
	font-weight: 400;
	font-size: 18px;
	font-style: italic;
	color: #fff;
}

.resources p {
	color: #fff;
}

.resources img {
	margin-bottom: 15px;
}

.gallery-strip h2 {
	text-align: center;
	color: $gray;
}

.flex-valign {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.mx-auto {
	margin: auto;
}

.flex-valign-row {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.pt3 {
	padding: 3rem 0 0 0;
}

.pt5 {
	padding: 5rem 0 0 0;
}

.pt6 {
	padding: 5rem 0 0 0;
}

.py3 {
	padding: 3rem 0;
}

.py5 {
	padding: 5rem 0;
}

.blue-band-programs {
	color: #fff;
	padding: 5rem 0;
}

.blue-band-programs p {
	font-size: 20px;
}

.red {
	color: #ed1b2e;
}


@media (max-width: 992px) {
	.packaging-solutions {
		margin: 30px 0 0 0;
	}
}
