.menu {
	display: none;
}

header {
	width: 100%;
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #fff),
		color-stop(100%, #d8d8d7)
	);
	background: -webkit-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -o-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -ms-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: linear-gradient(to bottom, #fff 0%, #d8d8d7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#d8d8d7',GradientType=0 );
}
header .logo {
	width: 339px;
	margin: 20px 0;
	transition: width 200ms ease-out;
}
header .utility-nav {
	padding: 0;
	margin: 40px 25px 0 0;
	list-style-type: none;
	font-weight: 400;
	font-size: 14px;
	float: right;
	text-transform: uppercase;
}
header .utility-nav li::after {
	content: ' | ';
}
header .utility-nav li:last-child::after {
	content: ' ';
}
header .utility-nav li {
	display: inline-block;
}
header .utility-nav li a {
	color: #535050;
	margin: 0 10px;
	font-size: 14px;
}
header {
	.btn-darkred,
	.btn-red {
		margin: 30px 0 0 0;
	}
}

nav {
	display: none;
	width: 100%;
	background-color: #464646;
}
nav ul {
	padding: 0;
	margin: 0;
	list-style: none;
	float: right;
	font-weight: 400;
	font-size: 16px;
	text-transform: uppercase;
}
nav ul li {
	display: inline-block;
}
nav ul li a {
	text-decoration: none;
	display: block;
	padding: 10px 20px;
	background-color: #464646;
	color: #fff;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
	font-size: 16px;
}
nav ul li a:hover {
	text-decoration: none;
	color: #fff;
	background-color: #000;
}
nav ul ul {
	display: none;
	position: absolute;
	width: 300px;
	z-index: 9999;
}
nav ul ul li {
	display: block;
}
nav ul ul li a {
	padding: 13px 13px 13px 30px;
	height: auto;
}
nav ul li:hover ul {
	display: block;
}

.sub-nav {
	margin: 0;
	padding: 0 0 30px 0;
	list-style: none;
}
.sub-nav li {
	margin: 15px 0;
}
.sub-nav a::before {
	content: ' > ';
}
.sub-nav a {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	color: #fff;
	font-weight: 400;
	font-size: 18px;
	text-decoration: none;
	text-align: left;
	border: 1px solid #e0e0e0;
	padding: 20px 20px;
	display: block;
	-webkit-transition: background-color 0.5s;
	-moz-transition: background-color 0.5s;
	-o-transition: background-color 0.5s;
	transition: background-color 0.5s;
	background-color: #1d617d;
}
.sub-nav a:hover {
	background-color: #fff;
	color: #8dc8e4;
}

.sticky {
	position: relative;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 8000;
	border-top: 0;
}

.stickyMobile {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 8000;
	border-top: 0;
}

.sticky {
	header .logo {
		width: 280px;
		margin: 8px 0;
	}
	header .utility-nav {
		margin: 25px 25px 0 0;
	}
	header .btn-darkred,
	header .btn-red {
		margin: 10px 0 0 0;
	}
	nav {
		box-shadow: 2px 2px 2px black;
	}
	nav ul li a {
		padding: 5px 20px;
	}
	nav ul li ul a {
		padding: 10px 20px;
	}
}

@media (min-width: 992px) {
	.btn,
	a.btn-red,
	footer input[type='submit'],
	a.btn-darkred,
	a.btn-green,
	a.btn-orange,
	a.btn-blue,
	a.btn-purp {
		display: inline-block;
	}
	nav {
		display: block;
	}
	.menu {
		display: block;
	}
	.slicknav_menu {
		display: none;
	}
	.img-margin {
		margin: 20px 20px 20px 0;
	}
	.sticky {
		position: fixed;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	header .utility-nav {
		margin: 40px 15px 0 -20px;
	}
	.sticky header .utility-nav {
		margin: 25px 15px 0 -20px;
	}
	header a.btn-red,
	.sticky header a.btn-red {
		padding: 10px;
	}
	nav ul li a,
	.sticky nav ul li a {
		padding: 5px 15px;
		font-size: 14px;
	}
	.mobile-hero {
		height: 200px;
	}
}
