.d-flex {
    display: flex;
    flex-wrap: wrap;
}
.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}

.white {
    color: #FFF;
}

.text-align-left{
    text-align: left;
}

.py-50 {
    padding: 50px 0;
}

@media (min-width: 480px) {
    .avoid-break {
      white-space: nowrap;
    }
  }