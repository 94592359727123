$red: #ed1b2e;
$dark-red: #c41626;
$gray: #464646;

@import "../node_modules/rfs/scss";
@import 'homepage';
@import 'header';
@import 'utilities';



.svg-clock {
	width: 90%;
	margin: 10px auto;
	display: block;
}

body {
	font-family: 'proxima-nova', Arial, sans-serif;
}

h1 {
	color: #fff;
	font-weight: 700;
	font-size: 60px;
	text-align: left;
	line-height: 100%;
	margin: 0;
}

h2,
h2 > a {
	color: #5a120f;
	font-weight: 700;
	font-size: 36px;
	line-height: 100%;
}

h2 > a {
	text-decoration: none;
}

h2 > a:hover {
	text-decoration: none;
}

h3 {
	color: #fff;
	font-weight: 700;
	font-size: 36px;
	text-align: center;
	line-height: 100%;
}

h4 {
	color: #8dc8e4;
	font-weight: 700;
	font-size: 24px;
	text-transform: uppercase;
	margin: 0;
	line-height: 100%;
}

h5 {
	color: #fff;
	font-weight: 700;
	font-size: 54px;
	margin: 0;
	line-height: 100%;
}

p,
ul,
ol,
a {
	font-weight: 400px;
	font-size: 18px;
	line-height: 24px;
}

sup {
	font-size: 70%;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.text-center {
	text-align: center;
}

.img-margin {
	margin: 0 10px 20px 0;
}

.pad30 {
	padding: 30px 0 30px 0;
}


.pad100 {
	padding: 100px 0;
}


.btn,
a.btn-red,
footer input[type='submit'],
a.btn-darkred,
a.btn-green,
a.btn-orange,
a.btn-blue,
a.btn-purp {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	text-transform: uppercase;
	color: #fff;
	padding: 10px 40px;
	text-decoration: none;
	display: inline-block;
	text-align: center;
}

a.btn-red,
footer input[type='submit'] {
	background-color: #ed1b2e;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
	border: none;
}

a.btn-red:hover,
footer input[type='submit']:hover {
	background-color: #5a120f;
}

a.btn-darkred {
	background-color: #5a120f;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
}

a.btn-darkred:hover {
	background-color: #ed1b2e;
}

a.btn-green {
	background-color: #2a9047;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
}

a.btn-green:hover {
	background-color: #ed1b2e;
}

a.btn-orange {
	background-color: #ed8520;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
}

a.btn-orange:hover {
	background-color: #ed1b2e;
}

a.btn-blue {
	background-color: #209ded;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
}

a.btn-blue:hover {
	background-color: #ed1b2e;
}

a.btn-purp {
	background-color: #6d20ed;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
}

a.btn-purp:hover {
	background-color: #ed1b2e;
}

footer {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #fff),
		color-stop(100%, #d8d8d7)
	);
	background: -webkit-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -o-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -ms-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: linear-gradient(to bottom, #fff 0%, #d8d8d7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#d8d8d7',GradientType=0 );
	border-top: 10px solid #535050;
	color: #535050;
	width: 100%;
	padding: 30px 0;
	a, p.small {
		font-size: 14px;
    	line-height: 1.5;
	}
}
footer h2 {
	color: #5a120f !important;
	font-weight: 700;
	font-size: 28px;
}
footer input[type='email'] {
	width: 100%;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	border: none;
	font-weight: 700;
	font-size: 15px;
	color: #535050;
	padding: 10px;
	margin-bottom: 20px;
}
footer .social {
	padding: 0;
	margin: 0;
	list-style: none;
	width: 265px;
	margin: 30px auto 30px auto;
}
footer .social li {
	background-image: url(../img/social.png);
	background-repeat: no-repeat;
	height: 43px;
	display: inline-block;
}
footer .social li.twitter,
footer .social li.linkedin,
footer .social li.facebook {
	width: 55px;
}
footer .social li.youtube {
	width: 85px;
}
footer .social li.linkedin {
	background-position: -55px 0;
}
footer .social li.facebook {
	background-position: -110px 0;
}
footer .social li.youtube {
	background-position: -165px 0;
}
footer .social a {
	display: block;
	height: 43px;
}

.blue-band {
	width: 100%;
	background: #093348;
	background: -moz-linear-gradient(top, #093348 0%, #125372 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #093348),
		color-stop(100%, #125372)
	);
	background: -webkit-linear-gradient(top, #093348 0%, #125372 100%);
	background: -o-linear-gradient(top, #093348 0%, #125372 100%);
	background: -ms-linear-gradient(top, #093348 0%, #125372 100%);
	background: linear-gradient(to bottom, #093348 0%, #125372 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b3a51', endColorstr='#125372',GradientType=0 );
	padding: 45px 0;
}
.blue-band .row .row {
	padding-bottom: 35px;
}
.blue-band p {
	color: #fff;
}
.blue-band a {
	color: #8dc8e4;
	text-decoration: none;
}
.blue-band a:hover {
	color: #fff;
	text-decoration: underline;
}

.gray-band {
	width: 100%;
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #fff),
		color-stop(100%, #d8d8d7)
	);
	background: -webkit-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -o-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: -ms-linear-gradient(top, #fff 0%, #d8d8d7 100%);
	background: linear-gradient(to bottom, #fff 0%, #d8d8d7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#d8d8d7',GradientType=0 );
	padding: 45px 0;
}

.thermoform-process-band {
	background: url(../img/thermoform-process-bg.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
	padding: 45px 0;
}
.thermoform-process-band p {
	color: #fff;
	text-align: center;
}

.our-process-band {
	background: url(../img/our-process-bg.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
	padding: 50px 0;
}
.our-process-band p {
	color: #fff;
	font-weight: 700;
	font-size: 22px;
	margin-top: 0;
}
.our-process-band .process-txt {
	color: #fff;
	font-weight: 700;
	font-size: 22px;
	font-style: italic;
	margin: 30px 0 40px 0;
}

.packaging-design-development {
	background: url(../img/design-hero.jpg) no-repeat center center;
}

.rapid-prototyping {
	background: url(../img/prototyping-hero.jpg) no-repeat center center;
}

.expert-tool-design-build {
	background: url(../img/tooling-hero.jpg) no-repeat center center;
}

.quality-control {
	background: url(../img/quality-hero.jpg) no-repeat center center;
}

.packaging-manufacturing-production {
	background: url(../img/manufacturing-hero.jpg) no-repeat center center;
}

.warehousing-shipping {
	background: url(../img/shipping-hero.jpg) no-repeat center center;
}

.landing-page-hero-trays {
	background: url(../img/landing-page-hero-trays.jpg) no-repeat center center;
}

.process-hero-band,
.packaging-design-development,
.rapid-prototyping,
.expert-tool-design-build,
.quality-control,
.packaging-manufacturing-production,
.warehousing-shipping,
.landing-page-hero-trays {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
	padding: 50px 0;
	height: 400px;
	margin-bottom: 30px;
}

/********INDUSTRY PAGES**********/

.black-band {
	background: linear-gradient(to top, #000 0%, #898989 100%);
	padding: 35px 0;
	.btn-red {
		margin: 20px 0 0 0;
	}
}

.industry-header .btn-red {
	margin: 20px 0 0 0;
}

.red-band,
.medical-pharma-packaging-band,
.food-packaging-band,
.consumer-retail-packaging-band,
.commercial-industrial-transport-band,
.blue-band-programs {
	width: 100%;
	padding: 35px 0;
}

.red-band {
	background: #5a120f;
	background: -moz-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #5a120f),
		color-stop(100%, #ed1b2e)
	);
	background: -webkit-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -o-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -ms-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: linear-gradient(to bottom, #5a120f 0%, #ed1b2e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a120f', endColorstr='#ed1b2e',GradientType=0 );
}

.medical-pharma-packaging-band p,
.medical-pharma-packaging-band ul,
.food-packaging-band p,
.food-packaging-band ul,
.consumer-retail-packaging-band p,
.consumer-retail-packaging-band ul,
.commercial-industrial-transport-band p,
.commercial-industrial-transport-band ul {
	color: #fff;
	font-size: 24px;
	line-height: 30px;
}

.medical-pharma-packaging-band,
.blue-band-programs {
	background: #173c4c;
	background: -moz-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #173c4c),
		color-stop(100%, #5194af)
	);
	background: -webkit-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -o-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: -ms-linear-gradient(top, #173c4c 0%, #5194af 100%);
	background: linear-gradient(to bottom, #173c4c 0%, #5194af 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#173c4c', endColorstr='#5194af',GradientType=0 );
}
.medical-pharma-packaging-band img {
	margin-top: -200px;
}

.food-packaging-band {
	background: #913909;
	background: -moz-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #913909),
		color-stop(100%, #fead6c)
	);
	background: -webkit-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -o-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: -ms-linear-gradient(top, #913909 0%, #fead6c 100%);
	background: linear-gradient(to bottom, #913909 0%, #fead6c 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#913909', endColorstr='#fead6c',GradientType=0 );
}
.food-packaging-band img {
	margin-top: -300px;
}

.consumer-retail-packaging-band {
	background: #062a0f;
	background: -moz-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #062a0f),
		color-stop(100%, #087e2a)
	);
	background: -webkit-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -o-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: -ms-linear-gradient(top, #062a0f 0%, #087e2a 100%);
	background: linear-gradient(to bottom, #062a0f 0%, #087e2a 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#062a0f', endColorstr='#087e2a',GradientType=0 );
}
.consumer-retail-packaging-band img {
	margin-top: -150px;
}

.commercial-industrial-transport-band {
	background: #100425;
	background: -moz-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #100425),
		color-stop(100%, #674c94)
	);
	background: -webkit-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -o-linear-gradient(top, #100425 0%, #674c94 100%);
	background: -ms-linear-gradient(top, #100425 0%, #674c94 100%);
	background: linear-gradient(to bottom, #100425 0%, #674c94 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#100425', endColorstr='#674c94',GradientType=0 );
}
.commercial-industrial-transport-band img {
	margin-top: -100px;
}

.consumer-retail-packaging-industry-bg {
	background: url(../img/industry-hero-retail.jpg) no-repeat center center;
}

.food-packaging-industry-bg {
	background: url(../img/industry-hero-food.jpg) no-repeat center center;
}

.medical-pharma-packaging-industry-bg {
	background: url(../img/industry-hero-medical.jpg) no-repeat center center;
}

.commercial-industrial-transport-industry-bg {
	background: url(../img/industry-hero-transport.jpg) no-repeat center center;
}

.industry-hero-band,
.consumer-retail-packaging-industry-bg,
.food-packaging-industry-bg,
.medical-pharma-packaging-industry-bg,
.commercial-industrial-transport-industry-bg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-align: center;
	padding: 50px 0;
	height: 330px;
}
.industry-hero-band p,
.consumer-retail-packaging-industry-bg p,
.food-packaging-industry-bg p,
.medical-pharma-packaging-industry-bg p,
.commercial-industrial-transport-industry-bg p {
	color: #fff;
	font-weight: 700;
	font-size: 22px;
	margin-top: 0;
}

.gallery-strip {
	padding: 0 0 30px 0;
}

.style-option {
	text-align: center;
}

.header {
	color: #ed1b2e;
	font-weight: 700;
	font-size: 36px;
	line-height: 100%;
	text-align: center;
}
.header span {
	text-transform: uppercase;
	font-weight: 700;
	color: #1d617d;
	font-size: 22px;
}

.case {
	text-align: center;
}
.case p {
	color: #093348;
	font-size: 24px;
}

.strip {
	width: 100%;
	height: 5px;
}

.purp {
	background-color: #6d20ed;
}

.green {
	background-color: #2a9047;
}

.blue {
	background-color: #209ded;
}

.orange {
	background-color: #ed8520;
}

.purp-txt {
	color: #6d20ed;
}

.green-txt {
	color: #2a9047;
}

.blue-txt {
	color: #209ded;
}

.orange-txt {
	color: #ed8520;
}

.basic-form {
	/* Form Field (selector for form field wrapper) */
}
.basic-form .hs-form {
	/* Labels (selects field labels and error messages) */
	/* Inputs (selectors for all inputs)  */
}
.basic-form .hs-form label,
.basic-form .hs-form .hs-form-field > label {
	margin-top: 1em;
	margin-bottom: 0.5em;
	color: #535050;
	display: block;
}
.basic-form .hs-form input[type='text'],
.basic-form .hs-form input[type='password'],
.basic-form .hs-form input[type='datetime'],
.basic-form .hs-form input[type='datetime-local'],
.basic-form .hs-form input[type='date'],
.basic-form .hs-form input[type='month'],
.basic-form .hs-form input[type='time'],
.basic-form .hs-form input[type='week'],
.basic-form .hs-form input[type='number'],
.basic-form .hs-form input[type='email'],
.basic-form .hs-form input[type='url'],
.basic-form .hs-form input[type='search'],
.basic-form .hs-form input[type='tel'],
.basic-form .hs-form input[type='color'],
.basic-form .hs-form input[type='file'],
.basic-form .hs-form textarea,
.basic-form .hs-form select {
	width: 100%;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.basic-form .hs-form input[type='submit'],
.basic-form .hs-form input[type='button'] {
	display: inline-block;
	border-radius: 3px;
	border: none;
	background-color: #5a120f;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 16px;
	padding: 10px 20px;
	margin: 10px 0 25px 0;
	text-transform: uppercase;
}
.basic-form .hs-form input[type='submit']:hover,
.basic-form .hs-form input[type='button']:hover,
.basic-form .hs-form input[type='submit']:focus,
.basic-form .hs-form input[type='button']:focus {
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
	background-color: #ed1b2e;
	cursor: pointer;
}
.basic-form .hs-form input[type='submit']:active,
.basic-form .hs-form input[type='button']:active {
	opacity: 1;
	box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1) inset;
}

.red-band-form {
	/* Form Field (selector for form field wrapper) */
}
.red-band-form .hs-form {
	/* Labels (selects field labels and error messages) */
	/* Inputs (selectors for all inputs)  */
}
.red-band-form .hs-form label,
.red-band-form .hs-form .hs-form-field > label {
	margin-top: 1em;
	margin-bottom: 0.5em;
	color: #fff;
	display: block;
}
.red-band-form .hs-form input[type='text'],
.red-band-form .hs-form input[type='password'],
.red-band-form .hs-form input[type='datetime'],
.red-band-form .hs-form input[type='datetime-local'],
.red-band-form .hs-form input[type='date'],
.red-band-form .hs-form input[type='month'],
.red-band-form .hs-form input[type='time'],
.red-band-form .hs-form input[type='week'],
.red-band-form .hs-form input[type='number'],
.red-band-form .hs-form input[type='email'],
.red-band-form .hs-form input[type='url'],
.red-band-form .hs-form input[type='search'],
.red-band-form .hs-form input[type='tel'],
.red-band-form .hs-form input[type='color'],
.red-band-form .hs-form input[type='file'],
.red-band-form .hs-form textarea,
.red-band-form .hs-form select {
	width: 100%;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.red-band-form .hs-form input[type='submit'],
.red-band-form .hs-form input[type='button'] {
	display: inline-block;
	border-radius: 3px;
	border: none;
	background-color: #5a120f;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 16px;
	padding: 10px 20px;
	margin: 10px 0 25px 0;
	text-transform: uppercase;
}
.red-band-form .hs-form input[type='submit']:hover,
.red-band-form .hs-form input[type='button']:hover,
.red-band-form .hs-form input[type='submit']:focus,
.red-band-form .hs-form input[type='button']:focus {
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
	background-color: #fff;
	cursor: pointer;
	color: #5a120f;
}
.red-band-form .hs-form input[type='submit']:active,
.red-band-form .hs-form input[type='button']:active {
	opacity: 1;
	box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1) inset;
}

.red-band-form {
	width: 100%;
	background: #5a120f;
	background: -moz-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #5a120f),
		color-stop(100%, #ed1b2e)
	);
	background: -webkit-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -o-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: -ms-linear-gradient(top, #5a120f 0%, #ed1b2e 100%);
	background: linear-gradient(to bottom, #5a120f 0%, #ed1b2e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a120f', endColorstr='#ed1b2e',GradientType=0 );
	padding: 45px 0;
}
.red-band-form h3 {
	color: #fff;
}
.red-band-form ul {
	padding: 0;
	margin: 0;
}
.red-band-form ul li {
	list-style: none;
	margin: 2em 0 0.5em 0;
}
.red-band-form p {
	color: #fff;
	text-align: center;
}
.red-band-form .field {
	margin: 0;
}
.red-band-form legend {
	color: #5a120f;
}
.red-band-form label {
	margin-top: 2em;
	margin-bottom: 0.5em;
	color: #fff;
}
.red-band-form input {
	width: 100%;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.red-band-form textarea {
	width: 100%;
	height: 150px;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.red-band-form button {
	display: inline-block;
	border-radius: 3px;
	border: none;
	background-color: #5a120f;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 16px;
	padding: 10px 20px;
	margin: 10px 0 25px 0;
	text-transform: uppercase;
}
.red-band-form button:hover,
.red-band-form button:focus {
	opacity: 0.75;
	cursor: pointer;
}
.red-band-form button:active {
	opacity: 1;
	box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1) inset;
}
.red-band-form input[type='checkbox'].checkbox {
	width: 40px;
}

.basic-form {
	width: 100%;
	padding: 0 0 30px 0;
}
.basic-form ul {
	padding: 0;
	margin: 0;
}
.basic-form ul li {
	list-style: none;
	margin: 0.5em 0 0.5em 0;
}
.basic-form p {
	color: #535050;
	text-align: center;
}
.basic-form .field {
	margin: 0;
}
.basic-form label {
	margin-top: 2em;
	margin-bottom: 0.5em;
	color: #535050;
}
.basic-form input {
	width: 100%;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.basic-form textarea {
	width: 100%;
	height: 150px;
	padding: 0.5em 0.5em;
	font-size: 1.2em;
	border-radius: 3px;
	border: 1px solid #d9d9d9;
	color: #535050;
}
.basic-form button {
	display: inline-block;
	border-radius: 3px;
	border: none;
	background-color: #5a120f;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 16px;
	padding: 10px 20px;
	margin: 10px 0 25px 0;
	text-transform: uppercase;
}
.basic-form button:hover,
.basic-form button:focus {
	opacity: 0.75;
	cursor: pointer;
}
.basic-form button:active {
	opacity: 1;
	box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1) inset;
}

.team-member {
	text-align: center;
}
.team-member img {
	margin-bottom: 20px;
}
.team-member .team-member-name {
	font-size: 22px;
	font-weight: 700;
	color: #ed1b2e;
}
.team-member .team-member-title {
	font-weight: 400;
	font-size: 18px;
	font-style: italic;
	color: #535050;
}

.flexbox-wrap a:hover {
	text-decoration: none;
}

.resource {
	display: flex;
	background-color: #5a120f;
	-webkit-transition: background-color 0.8s;
	-moz-transition: background-color 0.8s;
	-o-transition: background-color 0.8s;
	transition: background-color 0.8s;
	height: 300px;
	margin: 30px 0 0 0;
}
.resource .resource-content {
	text-align: center;
	text-decoration: none;
	color: #fff;
	margin: auto;
	font-size: 22px;
}
.resource .resource-content img {
	margin-bottom: 10px;
}
.resource .resource-content span {
	display: block;
}

.resource:hover {
	background-color: #ed1b2e;
}

.blog-block {
	border-bottom: 3px solid #e0e0e0;
	padding: 20px 0;
}
.blog-block .post-date {
	font-style: italic;
	color: #535050;
}

.filters-select {
	background: #ed1b2e;
	text-indent: 20px;
	font-size: 25px;
	font-weight: 400;
	color: #fff;
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
	margin: 20px 0 20px 0;
	border: none;
}

.isotope .thumb {
	margin-bottom: 15px;
	cursor: pointer;
}

.youtube-responsive {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
	margin-bottom: 30px;
}

.youtube-responsive iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.cycle-template .cycle-slideshow-api {
	height: auto;
}
.cycle-template .cycle-slideshow-api img {
	display: none;
}
.cycle-template .cycle-slideshow-api img.first {
	display: block;
}

.pns-sizes p,
.pns-sizes a {
	font-size: 14px;
	line-height: 18px;
}

.pns-sizes a {
	color: #ed1b2e;
}

.pns-sizes p {
	padding-left: 30px;
}

.brochures p {
	margin: 0 0 20px 0;
	text-align: center;
}

.tagline {
	color: #fff;
	font-size: 22px;
	display: block;
}

.pad50 {
	padding: 50px 0;
}

.hs-form-b9ff7e7a-f7e6-449a-a848-e07bb45d70c9_3e6a2874-9204-4fa2-bedc-d811359b5065.stacked label {
    display: block;
    float: none;
    width: auto;
    font-weight: 700;
    text-align: left;
    line-height: 20px;
    padding-top: 0;
    margin-bottom: 4px;
	color: #FFF;
}

.hs-form-b9ff7e7a-f7e6-449a-a848-e07bb45d70c9_3e6a2874-9204-4fa2-bedc-d811359b5065 .hs-input {
    box-sizing: border-box;
    max-width: 100%;
    width: 90%;
}

.cta-bar {
	background-color: #464646;
	padding: 25px 0;
	text-align: center;
	h3{
		margin: 0 0 10px 0;
	}
}